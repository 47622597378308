import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import config from "../../config";
import { API } from "aws-amplify";

export default class WorkoutNew extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      name: "",
      exercise: "",
      equipment: "",
      weight: "",
      variation: "",
      sets: 0,
      reps: 0,
    };
  }

  validateForm() {
    return this.state.name.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  handleSubmit = async event => {
    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE/1000000} MB.`);
      return;
    }

    this.setState({ isLoading: true });

    try {
      await this.createWorkout({
        name: this.state.name,
        exercise: this.state.exercise,
        equipment: this.state.equipment,
        weight: this.state.weight,
        variation: this.state.variation,
        sets: this.state.sets,
        reps: this.state.reps
      });
      this.props.history.push("/workouts");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  createWorkout(workout) {
    console.log("[info] A new workout has been created: ", workout);
    return API.post("notes", "workout", {
      body: workout
    });
  }

  render() {
    return (
      <div className="WorkoutNew">
        <div className="WorkoutNewHeader">
          <h1>
            Enter your workout details
          </h1>
        </div>
        <form onSubmit={this.handleSubmit}>
          <ControlLabel>Name</ControlLabel>
          <FormGroup controlId="name">
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="exercise">
            <ControlLabel>Exercise</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="equipment">
            <ControlLabel>Equipment</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="weight">
            <ControlLabel>Weight</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="variation">
            <ControlLabel>Variation</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="sets">
            <ControlLabel>Sets</ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input" 
              type="number"
            />
          </FormGroup>
          <FormGroup controlId="reps">
            <ControlLabel>Reps: </ControlLabel>
            <FormControl
              onChange={this.handleChange}
              value={this.state.content}
              componentClass="input" 
              type="number"
            />
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating…"
          />
        </form>
      </div>
    );
  }
}
