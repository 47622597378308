import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { API } from "aws-amplify";

export default class WorkoutView extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      isDeleting: null,
      workout: null,
      name: "",
      exercise: "",
      equipment: "",
      weight: "",
      variation: "",
      sets: 0,
      reps: 0,
    };
  }

  async componentDidMount() {
    try {
      const workout = await this.getWorkout();
      const { name, exercise, equipment, weight, variation, sets, reps } = workout;

      this.setState({
        workout,
        name,
        exercise,
        equipment,
        weight,
        variation,
        sets,
        reps
      });
    } catch (e) {
      alert(e);
    }
  }

  getWorkout() {
    console.log("[remove] API.get('notes', workout/" + this.props.match.params.id + ")", API.get("notes", `workout/${this.props.match.params.id}`));
    return API.get("notes", `workout/${this.props.match.params.id}`);
  }

  validateForm() {
    return this.state.name.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleFileChange = event => {
    this.file = event.target.files[0];
  }

  saveWorkout(workout) {
    console.log("[remove] API.put('notes', workout/:id)", API.put("notes", `workout/${this.props.match.params.id}`, {body: workout}));
    return API.put("notes", `workout/${this.props.match.params.id}`, {
      body: workout
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await this.saveWorkout({
        name: this.state.name,
        exercise: this.state.exercise,
        equipment: this.state.equipment,
        weight: this.state.weight,
        variation: this.state.variation,
        sets: this.state.sets,
        reps: this.state.reps
      });
      this.props.history.push("/workouts");
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  }

  deleteWorkout() {
    return API.del("notes", `workout/${this.props.match.params.id}`);
  }

  handleDelete = async event => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this workout?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteWorkout();
      this.props.history.push("/workouts");
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  }

  render() {
    return (
      <div className="Workouts">
        {this.state.workout &&
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="name">
              <ControlLabel>Name</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.name}
                componentClass="input"
              />
            </FormGroup>
            <FormGroup controlId="exercise">
              <ControlLabel>Exercise</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.exercise}
                componentClass="input"
              />
            </FormGroup>
            <FormGroup controlId="equipment">
              <ControlLabel>Equipment</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.equipment}
                componentClass="input"
              />
            </FormGroup>
            <FormGroup controlId="weight">
              <ControlLabel>Weight</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.weight}
                componentClass="input"
              />
            </FormGroup>
            <FormGroup controlId="variation">
              <ControlLabel>Variation</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.variation}
                componentClass="input"
              />
            </FormGroup>
            <FormGroup controlId="sets">
              <ControlLabel>Sets</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.sets}
                componentClass="input"
                type="number"
              />
            </FormGroup>
            <FormGroup controlId="reps">
              <ControlLabel>Reps</ControlLabel>
              <FormControl
                onChange={this.handleChange}
                value={this.state.reps}
                componentClass="input"
                type="number"
              />
            </FormGroup>
            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
            <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Delete"
              loadingText="Deleting…"
            />
          </form>}
      </div>
    );
  }
}
