const dev = {
  s3: {
    REGION: "ap-southeast-2",
    BUCKET: "omr-sydney-uploads-dev"
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://l4zwqp8fbi.execute-api.ap-southeast-2.amazonaws.com/dev/"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_S5xoyI7kq",
    APP_CLIENT_ID: "cpou2252iqhicjlut0vhv0m9",
    IDENTITY_POOL_ID: "ap-southeast-2:e4835e21-3f28-45ee-a78c-d00112d3bfaf"
  },
  STRIPE_KEY: "pk_test_yRDIIjfDG8UWURsu5ufRiorH"
};

const prod = {
  s3: {
    REGION: "ap-southeast-2",
    BUCKET: "omr-sydney-uploads-prod"
  },
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://6y9o3qjlql.execute-api.ap-southeast-2.amazonaws.com/prod/"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_BIs1Tu7eN",
    APP_CLIENT_ID: "21n59iu0gab8a5lia7guvutjj7",
    IDENTITY_POOL_ID: "ap-southeast-2:fc63c3ad-e1ba-4c66-a317-af83f22c1bcf"
  },
  STRIPE_KEY: "pk_test_yRDIIjfDG8UWURsu5ufRiorH"
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};
