import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
// import "./WorkoutsList.css";
import { API } from "aws-amplify";

export default class WorkoutsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      workoutsList: []
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const list = await this.getworkouts();
      this.setState({ workoutsList: list });
    } catch (e) {
      console.log('The system has encountered an error, please use the following error message for any support tickets:', e);
    }

    this.setState({ isLoading: false });
  }

  getworkouts() {
    return API.get("notes", "workout");
  }

  renderWorkoutsList(workoutsList) {
    return [{}].concat(workoutsList).map(
      (workout, i) =>
        i !== 0
          ? 
            <LinkContainer
              key={workout.workoutId}
              to={`/workouts/${workout.workoutId}`}
            >
              <ListGroupItem header={workout.name.trim().split("\n")[0]}>
                {"Created: " + new Date(workout.createdAt).toLocaleString()}
              </ListGroupItem>
            </LinkContainer>
          : <LinkContainer
              key="new"
              to="/workouts/new"
            >
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> Add workout
                </h4>
              </ListGroupItem>
            </LinkContainer>
    );
  }

  render() {
    return (
      <div className="WorkoutsList">
        <PageHeader>Your Workouts</PageHeader>
        <ListGroup>
          {!this.state.isLoading && this.renderWorkoutsList(this.state.workoutsList)}
        </ListGroup>
      </div>
    );
  }
}
