import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/home/Home";
import Login from "./containers/user/Login";
import Signup from "./containers/user/Signup";

import WorkoutsList from "./containers/workouts/WorkoutsList";
import WorkoutsNew from "./containers/workouts/WorkoutNew";
import WorkoutsView from "./containers/workouts/WorkoutView";

import NotFound from "./containers/misc/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

/*  Legacy Areas  */
import Billing from "./containers/billing/Billing";
import NewNote from "./containers/note/NewNote";
import Notes from "./containers/note/Notes";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
	  <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
  	<AuthenticatedRoute path="/billing" exact component={Billing} props={childProps} />
	  <AuthenticatedRoute path="/notes/new" exact component={NewNote} props={childProps} />
	  <AuthenticatedRoute path="/notes/:id" exact component={Notes} props={childProps} />
    <AuthenticatedRoute path="/workouts/" exact component={WorkoutsList} props={childProps} />
    <AuthenticatedRoute path="/workouts/new" exact component={WorkoutsNew} props={childProps} />
    <AuthenticatedRoute path="/workouts/:id" exact component={WorkoutsView} props={childProps} />
	{ /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;
